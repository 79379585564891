@import "reset";
@import "theme";
@import "bootstrap-grid.min.css";


* {
    box-sizing: border-box;
}
html {
    --baseFontSize: 16px;
    font-size: 62.5%;
}
body {
    font-family: "poppins";
    overflow-x: hidden;
    text-align: left;
    line-height: 1.7;
    font-size: 1.6rem;
    color: $secondaryColor;
}
a {
    text-decoration: none;
    color: $tertiaryColor;
}
p {
    margin: 0.375em 0;
    line-height: 1.6;
}
@media (min-width: 768px) {
    p {
        margin: 0.625em 0;
    }
}
.lead {
    font-size: 1.1rem;
}
img {
    max-width: 100%;
}


// bootstrap

.container {
    margin: 0 auto;
    max-width: 1200px;
}