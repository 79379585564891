@import "../../css/theme.scss";

.space {
    height: 1px;
    visibility: hidden;
}

.app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}


.footerContainer {
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
}