@import "../../css/theme.scss";

.heading {
    width: 100%;
    margin-bottom: .3em;
    font-weight: bold;
    line-height: 1.3;
    color: $baseFontColor;
}

.noMargin {
    margin: 0;
}

.align--center {
    text-align: center;
}

.align--right {
    text-align: right;
}

.h1 {
    font-size: 3rem;

    @media (min-width: 900px) {
        font-size: 6rem;
    }
}

.h2 {
    font-size: 3rem;
    @media (min-width: 900px) {
        font-size: 4.6rem;
    }
}

.h3 {
    font-size: 2.6rem;
    @media (min-width: 900px) {
        font-size: 3.4rem;
    }
}

.h4 {
    font-size: 2.2rem;
    @media (min-width: 900px) {
        font-size: 2.4rem;
    }
}

.h5 {
    font-size: 1.8rem;
    line-height: 1.5;
}

.h6 {
    font-size: 1.6rem;
    line-height: 1.5;
}

.subtitle {
    font-size: 2.2rem;
    line-height: 1.5;
    color: $tertiaryColor;
}