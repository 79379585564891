// my super duper modal
@import "../../css/theme.scss";

.modal {
    @include overlay;

    &:not(.isOpen) {animation: fadeOut 5s ease-in-out;}

    opacity: 0;

    &:not(.isOpen) {
        pointer-events: none;
        * {pointer-events: none !important;}
    }

    @media (min-width: 480px) {
        padding: 0 10px;
    }

    .modalCloser {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        
        &:hover {cursor: pointer;}
    }

    &.isOpen {
        opacity: 1;

        .modalContent {
            transform: translateY(0);
        }
    }
    .closeButton {
        text-align: right;
        line-height: 1;
        margin-bottom: 20px;

        i {
            color: #424242;
            font-size: 20px;
            &:hover {cursor: pointer;}
        }
    }

    .modalContent {
        background: #fff;
        padding: 10px;
        width: 100%;
        position: relative;
        z-index: 10000;
        max-height: 650px;
        overflow-y: auto;
        box-shadow: $boxShadow;
        transform: translateY(40px);
        transition: ease-in-out .2s transform;
        @media (max-width: 480px) {
            height: 350px;
        }

        @media (min-width: 480px) {
            border-radius: 4px;
            min-height: 100px;
            max-width: 1000px;
            margin: 50px auto 0;
        }

        @media (min-width: 768px) {
            padding: 30px;
            margin: 100px auto 0;
        }
    }
}
