@import "../../css/theme.scss";

/////////CARD STYLES/////////

.articleCard {
    @include cardAnimation;
    height: 100%;
    padding: 15px 0;

    &:hover {
        text-decoration: none;
        transform: translateY(-10px);
    }
}


/////////MEDIA STYLES/////////

.figure {
    margin-right: 20px;
    flex-shrink: 0;
}

.media {
    width: 125px;
}

.heading {
    margin-bottom: 30px;
}

.title {
    color: $tertiaryColor;
    text-transform: uppercase;
    border-top: 2px solid $borderColor;
    padding: 15px 75px 5px 0;
    display: inline-block;
    font-size: 1.4rem;
}

.bold {font-weight: bold;}

/////////CTA Container/////////
.ctaContainer {
    padding-top: 15px;
}