@import "../../css/theme.scss";

/////////THERE IS A VERTICAL AND HORIZONTAL SECTION VERSION OF THE NEWSLETTER COMPONENT/////////

/////////ICON STYLES/////////

.icon {
    font-size: 56px;
    line-height: 1em;
    padding: 0;
    color: #B4D7B8;
}

.signUpFormSection {
    .icon {
        display: none;
    }
}


/////////FORM STYLES/////////

.signUpForm {
    text-align: center;
    border-radius: 8px;
    align-items: center;
    min-width: 0;
    display: block;
    font-size: 1.4rem;
    box-shadow: 0 0 0 1px;
    height: 100%;
    padding: 15px;
    text-decoration: none;
    margin-bottom: 15px;
    background-color: #fff;
}

.signUpFormSection {
    background-color: $darkSection;
    padding: 30px;
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: center;
}


/////////TEXT STYLES/////////

.heading {
    font-size: 14px;
    max-width: 175px;
    margin-left: auto;
    margin-right: auto;
}

.signUpFormSection {
    .heading {
        font-size: 18px;
        max-width: 500px;
        margin-left: 0;
        margin-right: auto;
    }
}

.paragraph {
    display: none;
}

.paragraphLink {
    text-decoration: underline;
}

.signUpFormSection {
    .formContent {
        justify-content: center;
    }
}

.signUpFormSection {
    .paragraph {
        display: block;
    }
}

.signUpFormSection {
    .titleText {
        margin-right: auto;
    }
}


/////////FORM STYLES/////////

.formInput {
    border: 1px $borderColor solid;
    padding: 13px;
    width: 300px;
    border-radius: 2px;
    margin: 0;
}

@media (max-width: 520px) {
    .formInput {
        border: 1px $borderColor solid;
        padding: 13px;
        width: 170px;
        border-radius: 2px;
        margin: 0;
    }
}

.formInput:focus {
    outline: $darkButton 1px solid;
    border-radius: 2px;
}

.formButton {
    margin-left: 15px;
}

.formMessage {
    font-size: 13px;
    color: #1f5167;
}