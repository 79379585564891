@import "../../css/theme.scss";

/////////ANIMATIONS/////////

@keyframes float {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(0, 5px);
  }
  20% {
    transform: translate(0, 10px);
  }
  30% {
    transform: translate(0, 15px);
  }
  40% {
    transform: translate(0, 20px);
  }
  50% {
    transform: translate(0, 25px);
  }
  60% {
    transform: translate(0, 30px);
  }
  70% {
    transform: translate(0, 35px);
  }
  80% {
    transform: translate(0, 40px);
  }
  90% {
    transform: translate(0, 45px);
  }
  100% {
    transform: translate(0, 50px);
  }
}

@keyframes popout {
    from{transform:scale(0);}
    to{transform:scale(1);}
}


/////////BACKGROUND STYLES/////////

.thankYouHero {
    background: url("./images/victory-bg.jpg");
    background-size: cover;
    min-height: 100vh;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
}


/////////SCAFFOLDING/////////

.row {
    z-index: 30;
    position: relative;
    margin-top: 150px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    @media (min-width: 767px) {
        margin-bottom: 170px;
    }
    @media (max-width: 375px) {
        margin-top: 100px;
    }

}


/////////HEADER STYLES/////////

.heading {
    font-weight: $black;
    color: #fff;
}


/////////BODY STYLES/////////

.body {
    color: #fff;
    padding-bottom: 15px;
    max-width: 80%;
}

.content {
    position: relative;
    z-index: 999;
}


/////////TROPHY/////////

.trophyMedia {
    opacity: 0;
    transition: ease-in-out .3s opacity;
    width: 300px;

    :global(.loaded) .visible & {
        opacity: 1;
    }

    @media (max-width: 375px) {
        width: 250px;
    }
}

.trophy {
    width: 380px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    visibility: hidden;

    &.visible {visibility: visible;}

    @media (max-width: 768px) {
        margin-right: auto;
        transform: scale(.9);
    }

    @media (max-width: 500px) {
        transform: scale(.8);
    }
}

.trophyContainer {
    bottom: -80px;
    z-index: 1;

    @media (min-width: 768px) {
        bottom: -250px;
    }

    @media (max-width: 500px) {
        bottom: -90px;
    }
}


/////////CONFETTI SHARED STYLES/////////

.confetti {
    width: 15px;
    position: absolute;
    bottom: 0;
}



@mixin popout($size, $delay: .0s, $duration: .0s) {
    :global(html:not(.loaded)) & {opacity: 0;}

    :global(.loaded) .visible & {
        animation: popout $duration ease-in-out;
        animation-fill-mode: backwards;

        @if $size == 'small' {
            animation-delay: 0.3s + $delay;
            animation-duration: .3s + $duration;
        }

        @if $size == 'medium' {
            animation-delay: .3s + $delay;
            animation-duration: .3s + $duration;
        }

        @if $size == 'large' {
            animation-delay: .3s + $delay;
            animation-duration: .3s + $duration;
        }
    }
}


/////////LINE GROUP/////////

.line {
    @include popout('medium', .3s);
}

.confetti1 {
    top: -20px;
    right: 70px;
}

.confetti2 {
    bottom: 210px;
    right: 90px;
}

.confetti4 {
    bottom: 320px;
    right: 110px;
    position: absolute;
}

.confetti11 {
    animation-delay: .9s;
    top: -60px;
    left: -20px;
    width: 15px;
    position: absolute;
}

.confetti12 {
    top: -60px;
    left: 40px;
    width: 15px;
    position: absolute;
}

.confetti13 {
    animation-delay: .9s;
    bottom: 230px;
    left: -50px;
    position: absolute;
}

.confetti14 {
    top: -90px;
    left: 100px;
    width: 15px;
    position: absolute;
}

.confetti15 {
    animation-delay: .9s;
    top: -60px;
    left: 150px;
    width: 15px;
    position: absolute;
}

.confetti16 {
    top: -50px;
    right: 150px;
    width: 15px;
    position: absolute;
}

.confetti17 {
    animation-delay: .9s;
    bottom: 290px;
    left: -90px;
    position: absolute;
}

.confetti18 {
    bottom: 370px;
    left: -60px;
    position: absolute;
}

.confetti19 {
    animation-delay: .9s;
    bottom: 420px;
    right: 60px;
    position: absolute;
}

.confetti20 {
    bottom: 355px;
    left: 10px;
    position: absolute;
}


/////////STAR GROUP/////////

.star {
    @include popout('medium', .5s);
}

.confetti3 {
    position: relative;
    margin-bottom: 170px;
    margin-left: -195px;
}

.confetti21 {
    top: 50px;
    margin-left: -170px;
    z-index: 1;
}

.confetti22 {
    margin-bottom: 320px;
    right: 170px;
    width: 15px;
    position: absolute;
    bottom: 0;
}


/////////CIRCLE GROUP/////////

.circle {
    @include popout('large', .10s);
}

.confetti5 {
    margin-bottom: 470px;
    margin-left: -90px;
    transform: rotate(-15deg);
    width: 15px;
    position: absolute;
    bottom: 0;
    animation-duration: .4s;
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.confetti6 {
    margin-bottom: 370px;
    margin-left: 240px;
    width: 15px;
    position: absolute;
    bottom: 0;
    animation-duration: .4s;
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.confetti7 {
    margin-bottom: 220px;
    margin-left: 100px;
    animation-delay: 1.3s;
}

.confetti8 {
    position: relative;
    margin-bottom: 190px;
    margin-left: -130px;
    animation-delay: 1.3s;
}

.confetti10 {
    margin-bottom: 295px;
    margin-left: 240px;
}


// /////////CANDLES/////////

.candleContainer {
    display: flex;
    justify-content: center;
}

.candle {
    height: 80px;
    width: 80px;
    animation: float 8s linear infinite alternate;
    transition-timing-function: ease-in-out;
}

.candleOne {
    background: url("./images/candle-2.svg");
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    right: 0px;
    top: 30px;
}

.candleTwo {
    background: url("./images/candle-3.svg");
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    animation-direction: alternate-reverse;

    left: 100px;
    top: 90px;

    display: none;

    @media (min-width: 1200px) {
        display: inline;
    }
}

.candleThree {
    background: url("./images/candle-4.svg");
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    left: 105px;
    top: 30px;

    display: none;

    @media (min-width: 800px) {
        display: inline;
        left: 225px;
    }
}

.candleFour {
    background: url("./images/candle-5.svg");
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    left: 150px;
    top: 90px;
    animation-direction: alternate-reverse;

    @media (min-width: 500px) {
        right: 150px;
        left: 0;
    }
}

.candleFive {
    background: url("./images/candle-6.svg");
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    left: 40px;
    top: 120px;
}

.candleSix {
    background: url("./images/candle-7.svg");
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    left: 165px;
    top: 60px;
    animation-direction: alternate-reverse;

    display: none;

    @media (min-width: 840px) {
        display: inline;
        right: 230px;
    }
}

.candleSeven {
    background: url("./images/candle-8.svg");
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    right: 230px;
    top: 80px;

    display: none;

    @media (min-width: 1200px) {
        display: inline;
        right: 230px;
    }
}

.candleEight {
    background: url("./images/candle-9.svg");
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    right: 100px;
    top: 40px;
    animation-direction: alternate-reverse;
}