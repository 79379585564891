@import "../../css/theme.scss";

/////////TEXT STYLES/////////

.paragraph {
    margin: 0 0 1em;
}

.paragraphLink {
    text-decoration: underline;
}


/////////LIST STYLES/////////

.bulletedList {
    @include commandList;
}


/////////COMMAND STYLES/////////

.command {
    @include command;
}
.instructionsItem {
    @include instructionItem;
}


/////////NAVIGATION GENERAL STYLES/////////

.tabNavigation {
    display: flex;
    padding-bottom: 8px;
}

.menu {
    border-bottom: 2px $borderColor solid;
}

.navItem {
    padding: 10px 15px;
}

.navItem:hover {
    text-decoration: none;
}

.active {
    padding: 10px 15px;
    background: #f2f2f2;
    border-radius: 4px 4px 0 0;
}

.pageContent {
    margin-top: 30px;
}

.menu {
    padding-top: 60px;
}


/////////BUTTON STYLES/////////

.buttonMedia {
    width: 40px;
    display: inline-block;
    margin-left: -15px;
}


/////////CARD CONTENT STYLES/////////

.instructionsContent {
    border-radius: 8px;
    align-items: center;
    min-width: 0;
    display: block;
    font-size: 1.4rem;
    box-shadow: 0 0 0 1px $borderColor;
    transition: ease-in-out .2s box-shadow, ease-in-out .2s transform;
    height: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 30px;
    padding-bottom: 15px;
    text-decoration: none;

    @media (min-width: 768px) {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px;
        padding-bottom: 20px;
    }
}

.instructionsItem {
    margin: auto;
}

.paragraphLink {
    text-decoration: $underlineHover;
}


/////////SIDEBAR STYLES/////////

.sidebar {
    margin-top: 15px;
}


/////////ICON STYLES/////////

.icon {
    height: 50px;
    width: 50px;
    box-shadow: 1px 2px 25px 0 rgba(0,0,0,0.05);
    border-radius: 8px;
    font-weight: 900;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -24px;
    margin-left: 18px;
    position: relative;
    z-index: 10;
}
.icon i {
    color: $darkBackground;
    font-size: 26px;
}


/////////ICON COLOR STYLES/////////

.channelIcon {
    background-color: $channelColor;
}
.installIcon {
    background-color: $selfColor;
}
.userIcon {
    background-color: $userColor;
}
.registerIcon {
    background-color: $registerColor;
}

/////////GENERIC LIST STYLE/////////

.includedList {
    list-style-type:circle;
    padding-left: 30px;
}

.includedList li:last-of-type {
    font-style: italic;
}