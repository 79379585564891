@import "../../css/theme.scss";

/////////TEXT STYLES/////////

.title {
    color: $tertiaryColor;
    text-transform: uppercase;
    border-top: 2px solid $borderColor;
    padding: 15px 60px 15px 0;
    display: inline-block;
    font-size: 1.4rem;
}

.bold {font-weight: bold;}

.command {
    @include command;
}

@media (max-width: 501px) {
    .col {
        margin: 30px 0;
    }
}

.featureImage {
    border-radius: 8px;
}

body > div:not(#root) button {
    background: transparent;
    // padding: 0;
}
