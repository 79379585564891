@import '../../css/theme.scss';

/////////BUTTON STYLES AND TYPES/////////

.button  {
    @include buttonPrimary;

    .icon {
        padding-right: 6px;
        font-size: 18px;
    }

    &:hover {
        @include buttonPrimaryHover;
    }


/////////DISCORD STYLES/////////

    &--discordLight {
        color: $discord;
    }

    &--discordDark {
        background: $discord;
        color: $darkBackground;
        border: none !important;
    }


/////////SLACK STYLES/////////

    &--slack {
        color: #510F4D;
    }


/////////BUTTON SIZES/////////

    &--normal {
        padding: 13px 25px;
    }
    
    &--thin {
        padding: 10px 25px;
    }

/////////BUTTON SIZES/////////

    &--genericDark {
        background: $darkButton;
        color: $lightButton;
        border: none !important;
    }
}
