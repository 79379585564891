@import "../../css/theme.scss";

/////////HEADER STYLES/////////

.header {
    background-color: #fce7cf;
    position: sticky;
    width: 100%;
    padding: 10px 0;
    z-index: 1000;
    color: $baseFontColor;
    top: 0;
    transition: ease-in-out .2s background-color, ease-in-out .2s box-shadow;

    &.sticky {
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0,0,0,.2);
    }
}


/////////SCAFFOLDING/////////

.row {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr auto;
}


/////////HAMBURGER MENU ICON/////////

.icon {
    font-size: 30px;
    cursor: pointer;

    display: none;


    @media (max-width: 768px) {
        display: inline;
    }
}

.desktopNavigation {
    display: none;


    @media (min-width: 769px) {
        display: flex;
        align-items: center;
    }
}


/////////NAVIGATION/////////

.navigationItem {
    display: inline;
    margin: 15px;
    font-weight: $black;
}

.navigationItem a:hover {
    color: $baseFontColor;
}

.help {
    padding: 0;
}

.support {
    padding: 0px 10px;
}