@import "../../css/theme.scss";

.companiesUsingBotContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    border-radius: 8px;
}

.image {
    width: 115px;
}

.columbiaLogo {
    width: 130px;
}

.wideLogo {
    width: 200px;
}

.tallLogo {
    width: 60px;
}

.imageContainer {
    padding: 30px;
}

.noora {
    flex-grow: 1;
    display: block;
}

.heading {
    text-align: center;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
}