@import "../../css/theme.scss";

/////////SCAFFOLDING/////////
.row {
    align-items: center;
}

/////////MEDIA STYLES/////////
.media {
    width: 100%;
    display: flex;
}

.parallax img {
    max-width: 90vw;
    border-radius: 8px;
}

.platformImage {
    background-size: contain;
    min-width: 100%;
    background-repeat: no-repeat;
    border-radius: 8px;
    padding-top: 77%;
    animation: fadeIn ease-in-out .6s;
    box-shadow: 0 13px 18px -8px rgba(0,0,0,0.15);
}

@media (min-width: 502px) {
    .platformImage {
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.col {
    display: flex;
    align-items: stretch;
}


/////////BUTTON STYLES/////////

.button {
    margin-top: 15px;
}


/////////VIDEO STYLES/////////

.video {
    animation: fadeIn ease-in-out .6s;
    box-shadow: 0 13px 18px -8px rgba(0,0,0,0.15);
}

@media (min-width: 768px) {
    .video {
        position: relative;
    }
}

.videoContainer {
    border-radius: 8px;
    height: 550px;
    width: 558px;
    overflow: hidden;
}