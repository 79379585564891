@import "../../css/theme.scss";

.heading {
    margin-bottom: 1em;
}

.paragraph {margin: 0 0 1em;}

.subhead {
    text-decoration: underline;
    font-weight: bold;
}

.orderedList {
    list-style-type: decimal;
    margin-left: 3em;
    margin-bottom: 1em;
}

.unorderedList {
    list-style-type: disc;
    margin-left: 3em;
    margin-bottom: 1em;
}