@import "../../css/theme.scss";

/////////CARD STYLES/////////

.card {
    border-radius: 8px;
    align-items: center;
    min-width: 0;
    display: block;
    font-size: 1.4rem;
    box-shadow: 0 0 0 1px $borderColor;
    transition: ease-in-out .2s box-shadow, ease-in-out .2s transform;
    height: 100%;
    padding: 15px;
    text-decoration: none;
    flex-grow: 1;

    @media (min-width: 768px) {
        padding: 20px;
    }


/////////HORIZONTAL KIND STYLES/////////

    &--horizontal {
        .media {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .mediaHeading {
            font-size: 14px;
            margin-bottom: 0;
            line-height: 1.25em;
        }
        .mediaBody {
            font-size: 14px;
        }
        .mediaFigure {
            margin-right: 10px;
        }
        @media (min-width: 576px){
            .mediaHeading {
                font-size: 14px;
                line-height: 1.25em;
            }
            .mediaBody {
                font-size: 14px;
            }
            .mediaFigure {
                margin-right: 15px;
            }
        }
    }


/////////COLOR STYLES/////////

    &--light {
        .mediaHeading {
            color: $darkBackground;
        }
        .mediaBody {
            color: $darkBackground;
        }
        .mediaFigure {
            color: $darkBackground;
        }
        .mediaButton {
            color: $darkBackground;
        }
    }

    &--dark {
        .mediaHeading {
            color: $baseFontColor;
        }
        .mediaBody {
            color: $baseFontColor;
        }
        .mediaFigure {
            color: $baseFontColor;
        }
    }


/////////ALIGNMENT/////////

    &.alignTop {align-items: flex-start;}
    &.alignStretch {align-items: stretch;}


/////////HOVER STATE STYLES/////////

    &:hover {
        box-shadow: $boxShadow;
        border-color: transparent;
    }
}


/////////MEDIA STYLES/////////

.media {
    align-items: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}


/////////MEDIA CONTENT/////////

.mediaContent {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mediaBody {
    flex-grow: 1;
}

.mediaBody:hover {
    text-decoration: none;
}


/////////MEDIA BUTTON/////////

.mediaButton {
    color: $tertiaryColor;
    font-size: 14px;
    font-weight: 600;
}

.mediaButton:hover {
    text-decoration: $underlineHover;
}

