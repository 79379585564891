@import "../../css/theme.scss";

/////////ANIMATIONS/////////

@keyframes popout {
    from{transform:scale(0);}
    to{transform:scale(1);}
}




/////////POT/////////

.potMedia {
    opacity: 0;
    transition: ease-in-out .3s opacity;
    width: 380px;

    :global(.loaded) .visible & {
        opacity: 1;
    }
}

.pot {
    width: 290px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    visibility: hidden;

    &.visible {visibility: visible;}

    @media (max-width: 768px) {
        transform: scale(.8);
    }

    @media (max-width: 500px) {
        transform: scale(.6);
    }
}

.heading {
    position: absolute;
    text-align: center;
    top: 38px;
    color: #FFF;
    font-size: 20px;
    text-shadow: 5px -3px rgba(0,0,0,.2);
}

.headingBlockText {
    display: block;
    font-size: 80px;
}

/////////BUBBLE SHARED STYLES/////////

.bubble {
    position: absolute;
    bottom: 0;
}

// @mixin popout($size, $delay: .0s, $duration: .0s) {
//     :global(html:not(.loaded)) & {opacity: 0;}

//     :global(.loaded) .visible & {
//         animation: popout $duration ease-in-out;
//         animation-fill-mode: backwards;
   
//         animation-iteration-count: infinite;

//         @if $size == 'small' {
//             animation-delay: 0.3s + $delay;
//             animation-duration: 2s + $duration;
//         }

//         @if $size == 'medium' {
//             animation-delay: .3s + $delay;
//             animation-duration: 1.5s + $duration;
//         }

//         @if $size == 'large' {
//             animation-delay: .3s + $delay;
//             animation-duration: 3s + $duration;
//         }
//     }
// }

@mixin arrowAnimate($delay: .0s) {
    :global(html:not(.loaded)) & {opacity: 0;}

    :global(.loaded) .visible & {
        animation: popout .3s (.4s + $delay) cubic-bezier(0.68, -0.55, 0.27, 1.55);
        animation-fill-mode: backwards;
    }
}


/////////BUBBLE INDIVIDUAL STYLES/////////

.bubble1{
    bottom: 300px;
    left: 205px;
    width: 60px;
    animation: popout;
    animation-delay: 0.3s;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.bubble2 {
    bottom: 300px;
    left: 100px;
    width: 20px;
    animation: popout;
    animation-delay: 0.8s;
    animation-duration: 1.9s;
    animation-iteration-count: infinite;
}

.bubble3 {
    bottom: 353px;
    left: 105px;
    width: 50px;
    animation: popout;
    animation-delay: 0s;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
}

.bubble4 {
    bottom: 320px;
    right: 109px;
    width: 25px;
    position: absolute;
    animation: popout;
    animation-delay: 1.2s;
    animation-duration: 2.3s;
    animation-iteration-count: infinite;
}

.bubble5 {
    bottom: 320px;
    left: 27px;
    width: 60px;
    animation: popout;
    animation-delay: 1s;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.bubble6 {
    bottom: 412px;
    left: 65px;
    z-index: 100;
    width: 35px;
    animation: popout;
    animation-delay: 1.7s;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
}

.bubble7 {
    bottom: 382px;
    right: 63px;
    width: 40px;
    animation: popout;
    animation-delay: 3s;
    animation-duration: 1.8s;
    animation-iteration-count: infinite;
}

.bubble8 {
    width: 30px;
    position: absolute;
    right: 144px;
    bottom: 467px;
    animation: popout;
    animation-delay: 1.3s;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
}

.bubble9 {
    bottom: 420px;
    left: 160px;
    width: 20px;
    animation-delay: .7s;
    position: relative;
    animation: popout;
    animation-delay: 1s;
    animation-duration: 1.7s;
    animation-iteration-count: infinite;
}

/////////TEXT STYLES/////////

.bodyContent {
    text-align: center;
}

.paragraphLink {
    text-decoration: underline;
}

/////////CONTAINER STYLES/////////

.row {
    margin-top: 200px;
}

@media (max-width: 500px) {
    .row {
        margin-top: 60px;
    }
}
