@import '../../css/theme.scss';

@keyframes floatUp {
    0% {
        opacity: 0;
        transform: translateY(100px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.alert {
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 20px;
    background: $lightSection;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid rgba(0,0,0,.12);
    box-shadow: $boxShadow;
    border-left: 5px solid $darkButton;
    z-index: 1000;
    max-width: 450px;
    display: none;
    animation: floatUp ease-in-out .4s .5s both;

    &.visible {
        display: flex;
    }
}

.close {
    font-size: 20px;
    line-height: 1;

    &:hover {
        color: $darkButton;
        cursor: pointer;
    }
}

a:hover {
    text-decoration: underline;
}
