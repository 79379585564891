
/////////MIXINS/////////

/////////ICONS/////////

@mixin icon {
    color: #fff;
    height: 50px;
    width: 50px;
    box-shadow: 1px 2px 25px 0 rgba(0,0,0,0.05);
    border-radius: 8px;
    font-weight: 900;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -25px;
    margin-left: 18px;
}


/////////BORDER/////////

@mixin border {
    border-color: $borderColor;
    border-radius: 15px;
    border-width: .5px;
    border-style: solid;
    height: 250px;
}

@mixin borderHover {
    background-color: #fff;
    border-style: none;
}


/////////CARD ANIMATION/////////

@mixin cardAnimation {
    transition: ease-in .15s transform;
    transform: translateY(0px);
    display: flex;
}

/////////COMMANDS/////////

@mixin command {
    background: #a0d1d9;
    padding: 2px 7px;
    border-radius: 2px;
    color: $baseFontColor;
    font-weight: 600;
}

@mixin commandList {
    font-size: 1.4rem;
    margin-bottom: 20px;

    li {
        margin-bottom: 10px;
    }
}

/////////INSTRUCTIONS/////////

@mixin instructionItem {
    list-style-type: decimal;
    padding: 10px 0;
    margin-left: 15px;
}


/////////BUTTON PRIMARY STYLES/////////

@mixin buttonPrimary {
    padding: 13px 20px;
    font-family: lato;
    font-size: 14px;
    font-weight: 900;
    background-color: $lightSection;
    border: 1px solid $borderColor;
    border-bottom-width: 3px;
    border-style: solid;
    border-radius: 4px;
    color: $secondaryColor;
    display: inline-flex;
    line-height: 1;
    align-items: center;
    justify-content: center;
    height: 45px;
    transform: scale3d(1,1,1);
    transition: ease-in-out .1s all;
}

@mixin buttonPrimaryHover {
    cursor: pointer;
    transform: scale3d(1.04,1.04,1.04);
    text-decoration: none;
}

/////////DOCUMENTATION NAVIGATION/////////

@mixin docsNavigation {
    display: flex;
    border-bottom: 2px $borderColor solid;
}

@mixin navItem {
    outline: 0;
    padding: 0 10px;
}

@mixin navItemElements {
    outline: 0;
    cursor: pointer;
    color: $tertiaryColor;
    padding: 0px;
    display: flex;
    padding: 0 10px;
    background: transparent;

    @media (min-width: 576px) {
            padding: 10px 5px;
    }
}


/////////MODAL OVERLAY/////////

@mixin overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 999999;
    transition: ease-in-out .2s opacity;
}


/////////BACKGROUND COLORS/////////

$primaryColor: #fce7cf;
$darkSection: #87b7bf;
$lightSection: #fff;


/////////FONT FAMILIES/////////

$fontFamily: "Poppins";


/////////FONT COLORS/////////

$baseFontColor: #212121;
$secondaryColor: #424242;
$textColorFaint: rgba(43,41,45,0.6);
$tertiaryColor: #1f5167;
$darkBackground: #fff;


/////////BUTTON COLORS/////////

$lightButton: #fff;
$darkButton: #1f5167;
$discord: #7189da;
$slack: #510F4D;


/////////BUTTON HOVER COLORS/////////

$darkButtonHover: #306f8c;

/////////FONT WEIGHTS/////////

$normal: 400;
$bold: 600;
$black: 700;


/////////FONT SIZES--DESKTOP/////////

$heroBigFont: 3.75em;
$h1: 2.875em;
$h2: 2.125em;
$h3: 1.5em;
$h4: 1.375em;
$h5: 1.125em;
$h6: 1rem;

$lead: 1.125rem;
$body: 1rem;

$subtitle: 0.875rem;


/////////FONT SIZES--MOBILE/////////

$heroBigFontMobile: 2.875rem;
$h1mobile: 2.125rem;
$h2mobile: 2rem;
$h3mobile: 1.5rem;
$h4mobile: 1.375rem;
$h5mobile: 1rem;
$h6mobile: 0.875rem;

$leadMobile: 0.875rem;
$bodyMobile: 0.75rem;

$subtitleMobile: 0.75rem;


/////////BOX SHADOW/////////

$boxShadow: 0 5px 15px 0 rgba(0,0,0,0.07),
            0 15px 35px 0 rgba(50,50,93,0.1);


/////////BORDER COLOR/////////

$borderColor: #d9d7d7;
$borderColorDark: #212121;


/////////BUTTON UNDERLINE/////////

$underlineHover: underline;


/////////MODAL COLOR STYLES/////////

//learn-icon-color
$channelColor: #F7BC14;

//get-icon-color
$selfColor: #84C5B4;

//contribute-icon-color
$userColor: #337B9C;

//share-icon-color
$registerColor: #374365;
