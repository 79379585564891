@import "../../css/theme.scss";


/////////BUTTON STYLES/////////

.button:hover {
    text-decoration: none;
}

.buttonRow {
    display: flex;
    align-items: center;

    margin-left: -10px;

    & .button {
        margin-left: 10px;
    }
}