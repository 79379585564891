@import "../../css/theme.scss";

/////////TEXT STYLES/////////

.paragraph {
    margin: 0 0 1em;
    flex-grow: 1;
}

.chatHeading {
    font-size: 24px;
}

.helpHeading {
    font-size: 34px;
}

/////////ICON STYLES/////////

.icon {
    height: 50px;
    width: 50px;
    border-radius: 8px;
    font-weight: 900;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -24px;
    //margin-left: 18px;
    position: relative;
    z-index: 10;
}
.icon i {
    color: $darkBackground;
    font-size: 26px;
}


/////////ICON COLORS/////////

.supportIcon {
    background-color: $selfColor;
}

.discordIcon {
    background-color: $discord;
}

.slackIcon {
    background-color: $slack;
}

.githubIcon {
    background-color: #000;
}


/////////CONTENT STYLES/////////

.supportContent {
    border-radius: 8px;
    min-width: 0;
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    box-shadow: 0 0 0 1px $borderColor;
    transition: ease-in-out .2s box-shadow, ease-in-out .2s transform;
    height: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 30px;
    padding-bottom: 15px;
    text-decoration: none;

    @media (min-width: 768px) {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px;
        padding-bottom: 20px;
    }
}

.supportItem {
    margin: 20px auto;
    display: flex;
}

.buttonRow {
    gap: 20px;
    display: flex;
    flex-flow: row wrap;
    margin-top: 30px;
    margin-bottom: 40px;
}

.documentationWrapper {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 10px;

    h5 {
        margin-bottom: 0px;
    }

    p {
        margin-top: 5px;
    }
}