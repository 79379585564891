@import "../../css/theme.scss";

/////////SCAFFOLDING STYLES/////////

.section {
    text-align: center;
}

.row {
    position: relative;
}

.col {
    margin-bottom: 30px;
}

@media (min-width: 768px) {
    .col {
        margin-bottom: 0px;
    }
}


/////////TEXT STYLES/////////

.heading {
    margin-bottom: 40px;
}

.paragraph {
    margin-top: 40px;
}

.mediaBody {
    width: 250px;
    margin-left: auto;
    margin-right: auto;
}

.paragraphLink {
    text-decoration: $underlineHover;
}


/////////CARD STYLES/////////

.card {
    text-align:center;
    padding: 30px;
    width: 100%;
}

@media (min-width: 768px) {
    .card {
        width: 350px;
    }
}

.slackCard {
    margin-left: 0px;
}

@media (min-width: 768px) {
    .slackCard {
        margin-left: auto;
    }
}

.programLink {
    height: 100%;
}


/////////CARD IMAGE STYLES/////////

.program {
    border-radius: 100px;
    width: 150px;
    margin-bottom: 15px;
}

.slack {
    background-color: #510F4D;
}

.discord {
    background-color: $discord;
}


/////////CARD BUTTON STYLES/////////

.button {
    @include buttonPrimary;
    margin-top: 15px;
}