@import "../../css/theme.scss";

/////////BACK BUTTON STYLES/////////

.backButton {
    position: absolute;
    top: 10px;
    left: 10px;
    @media (min-width: 768px) {
        top: 30px;
        left: 30px;
    }
    opacity: 0;

    &.active {
        opacity: 100;
        @media (min-width: 768px) {
            transition: ease-in-out .4s .2s opacity;
        }
    }

    &:hover {
        cursor: pointer;
    }
}


/////////TEXT STYLES/////////

.lead {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-weight: $normal;

    @media (min-width: 575px) {
        max-width: 600px;
    }
}


/////////MODAL STYLES/////////

.setup {
    display: flex;
    flex-direction: column;
}

.tab {
    opacity: 0;
    position: absolute;
    // left: 0px;
    // right: 0px;
    display: none;
    @media (min-width: 768px) {
        // left: 30px;
        // right: 30px;
        display: block;
    }
    pointer-events: none;
    order: 1;
    overflow: hidden;

    &.activeTab {
        opacity: 1;
        display: block;
        transition: ease-in-out .4s .2s opacity;
        position: static;
        pointer-events: auto;
        order: 0;
    }
}

.heading {
    font-size: 14px;
    max-width: 175px;
    margin-left: auto;
    margin-right: auto;
}


/////////FORM CONTAINER STYLES/////////

.formContainer {
    text-align: center;
}


/////////FORM STYLES/////////

.formContainer label {
    display:none;
}

.formInput {
    border: 1px $borderColor solid;
    padding: 13px;
    width: 400px;
    border-radius: 2px;
}

@media (max-width: 570px) {
    .formInput {
        width: 250px;
    }
}

.formInput:focus {
    outline: $darkButton 1px solid;
    border-radius: 2px;
}

.formButton {
    @include buttonPrimary;

    margin-left: 15px;

    &:hover {
        @include buttonPrimaryHover;
    }
}

@media (max-width: 420px) {
    .formButton {
        margin: 15px;
    }
}

.formMessage {
    font-size: 13px;
    color: #c4c4c4;
}

.successImage {
    height: 25px;
    width: 25px;
}

.formConfirmation {
    color: $selfColor;
}

.formConfirmationContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

/////////SKIP BUTTON STYLES/////////

.skipButton {
    color: #c4c4c4;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.arrowIcon {
    margin-bottom: -4px;
    padding: 5px;
}

.newsletterForm p {
    color: #c4c4c4 !important;
    font-size: 14px !important;
}