@import "../../css/theme.scss";

// /////////FINAL THINGS TO CLICK STYLES/////////

.finalThingsToClick {
    background: $primaryColor;
    top: 0;
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-bottom: -110px;
}

@media (min-width: 768px) {
    .finalThingsToClick {
        text-align: left;
    }
}

@media (max-width: 768px) AND (min-width: 500px) {
    .finalThingsToClick {
        margin-bottom: 0;
    }
}

@media (max-width: 500px) {
    .finalThingsToClick {
        overflow: hidden;
    }
}


// // /////////TEXT STYLES/////////

.header {
   font-weight: $black;
   color: $baseFontColor;
}

.subtitle {
   margin: 0;
   color: $textColorFaint;
   font-weight: 300;
   width: 100%;
   font-size: 1.8rem;
   text-align: center;

   @media (min-width: 622px) {
       text-align: left;
   }
}


// // /////////TEXTURE/////////

.textureContainer {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url("./images/hero-bg-texture.png");
    pointer-events: none;
    background-repeat: repeat;
    position: absolute;
    mix-blend-mode: luminosity;
    z-index: 5;
}

@media (min-width: 505px) {
    .textureContainer {
        bottom: 0px;
    }
}


// // /////////SCAFFOLDING/////////

.row {
    z-index: 30;
    position: relative;
    padding: 90px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

@media (max-width: 500px) {
    .row {
        padding: 30px 0 150px 0;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

.buttonContainer {
    display: flex;
    justify-content: center;
}

@media (min-width: 622px) {
    .buttonContainer {
        justify-content: flex-start;
    }
}


// // /////////HOUSE CUP LOGO/////////

.mediaFigure {
    text-align: right;
    padding: 30px;
    width: 200px;

    @media (min-width: 768px) {
        width: 240px;
    }
}


// // /////////MIDDLEGROUND/////////

.backTreeline {
    background: url("./images/back-treeline.svg");
    position: absolute;
    background-size: cover;
    bottom: -80px;
    height: 300px;
    width: 800px;
    left: -120px;
    transform: rotateY(180deg);
}

@media (min-width: 670px) {
    .backTreeline {
        bottom: -20px;
        height: 300px;
        width: 800px;
        left: unset;
        right: -120px;
    }
}

.hoopParallax {
    background: url("./images/hoops.svg");
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    right: -90px;
    bottom: 0px;
    height: 450px;
    width: 250px;
}

@media (min-width: 572px) {
    .hoopParallax {
        right: -30px;
        bottom: -70px;
    }
}

@media (min-width: 670px) {
    .hoopParallax {
        right: -30px;
        bottom: -60px;
    }
}

.frontTreeline {
    background: url("./images/front-treeline.svg");
    position: absolute;
    background-size: cover;
    bottom: -90px;
    height: 250px;
    width: 800px;
    left: 0;
    transform: rotateY(180deg);
}

@media (min-width: 670px) {
    .frontTreeline {
        left: unset;
        right: -120px;
        bottom: -30px;
    }
}
