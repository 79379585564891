@import "../../css/theme.scss";

/////////BACK BUTTON STYLES/////////

.backButton {
    position: absolute;
    top: 10px;
    left: 10px;
    @media (min-width: 768px) {
        top: 30px;
        left: 30px;
    }
    opacity: 0;

    &.active {
        opacity: 100;
        @media (min-width: 768px) {
            transition: ease-in-out .4s .2s opacity;
        }
    }

    &:hover {
        cursor: pointer;
    }
}


/////////MODAL STYLES/////////

.setup {
    display: flex;
    flex-direction: column;
}

.tab {
    opacity: 0;
    position: absolute;
    display: none;
    max-height: 100vh;
    pointer-events: none;
    order: 1;

    &.activeTab {
        opacity: 1;
        display: block;
        transition: ease-in-out .4s .2s opacity;
        position: static;
        pointer-events: auto;
        order: 0;
    }
}


/////////SOCIAL MEDIA STYLES/////////

.socialMediaLinks {
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    position: absolute;
    bottom: 0;
    margin-bottom: 5px;
}

.socialMediaIcon {
    padding: 5px;
    
    i {
        font-size: 20px;
        line-height: 1;
    }

    a {
        padding: 10px;
    }
}


/////////NAVIGATION STYLES/////////

.navigationItem {
    border-bottom: 1px solid $borderColor;
    padding: 10px 0;
    font-weight: $black;
}

.subnavigationItem {
    width: 120px;
}
