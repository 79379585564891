// my super duper modal
@import "../../css/theme.scss";

.modal {
    @include overlay;

    &:not(.isOpen) {animation: fadeOut 5s ease-in-out;}

    opacity: 0;

    &:not(.isOpen) {
        pointer-events: none;
        * {pointer-events: none !important;}
    }

    @media (min-width: 480px) {
        padding: 0 10px;
    }

    .modalCloser {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        
        &:hover {cursor: pointer;}
    }

    &.isOpen {
        opacity: 1;

        .modalContent {
            transform: translateY(0);
        }
    }

    .closeButton {
        text-align: right;
        line-height: 1;
        margin-bottom: 20px;

        i {
            color: #424242;
            font-size: 20px;
            &:hover {cursor: pointer;}
        }
    }

}

.modalContent {
    background: #fff;
    z-index: 10000;
    width: 100vw;
    height: 375px;
    margin-top: 90px;
    padding: 30px;
    position: relative;
    z-index: 10000;
    box-shadow: $boxShadow;
    transform: translateY(40px);
    transition: ease-in-out .2s transform;
}

@media (min-width: 500px) {
    .modalContent {
        margin-left: -20px;
    }
}
