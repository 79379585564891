@import '../../css/theme.scss';


//Section Styles and Types

.section  {
    padding-top: 40px;
    padding-bottom: 40px;
    @media (min-width: 768px) {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    &--primary {
        background-color: #fff;
    }

    &--secondary {
        background-color: #F3F5F6;
    }
    &--dark {
        background: #9ac7ce;
    }
    &--xsmall {
        padding: 20px 0;
    }
    &--small {
        padding: 40px 0;
    }
    &--special {
        padding-top: 80px;
        padding-bottom: 0;
    }
}
