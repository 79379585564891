@import "../../css/theme.scss";

/////////SCAFFOLDING STYLES/////////

.row {
    display: flex;
    align-items: center;
    overflow-x: hidden;
}


/////////TEXT CONTAINER STYLES/////////

.teamInfo {
    margin-top: 30px;
}
@media (min-width: 575px) {
    .teamInfo {
        margin-left: 30px;
        margin-top: 0px;
    }
}

.houseCupTeams {
    margin-right: auto;
    margin-left: auto;
}


/////////TEXT STYLES/////////

.heading {
    font-size: 14px;
    font-weight: 600;
}

.sectionContent {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 1035px) {
        margin-right: 0;
    }
}

.cardHeading {
    font-size: 14px;
    letter-spacing: -.04em;
}

.cardTeam {
    font-size: 14px;
    letter-spacing: -.04em;
}


/////////CARD CONTAINER STYLES/////////

.card {
    padding: 15px 10px;
    text-align: center;
    background: $lightSection;
    max-height: 140px;
    max-width: 100px;
    align-items: center;
    border-radius: 8px;
    margin: 10px;
    text-align: center;
}

.lastRow {
    margin-top: 15px;
}

@media (max-width: 576px) {
    .anitaCard {
        display: none;
    }
}

@media (max-width: 500px) {
    .joshCard {
        display: none;
    }
}

@media (max-width: 500px) {
    .chrisCard {
        display: none;
    }
}


/////////IMAGE STYLES/////////

.cardImage {
    max-width: 60px;
}
