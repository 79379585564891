@import "../../css/theme.scss";

/////////ANIMATIONS/////////

@keyframes float {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(5px, 0);
  }
  20% {
    transform: translate(10px, 0);
  }
  30% {
    transform: translate(15px, 0);
  }
  40% {
    transform: translate(20px, 0);
  }
  50% {
    transform: translate(25px, 0);
  }
  60% {
    transform: translate(30px, 0);
  }
  70% {
    transform: translate(35px, 0);
  }
  80% {
    transform: translate(40px, 0);
  }
  90% {
    transform: translate(45px, 0);
  }
  100% {
    transform: translate(50px, 0);
  }
}

@keyframes popout {
    from{transform:scale(0);}
    to{transform:scale(1);}
}

@keyframes bounceUp {
    from{
        bottom: -50px;
        opacity: 0;
    }
    to{
        bottom: 0;
        opacity: 100%;
    }
}

@keyframes fadeRight {
    from{
        left: -50px;
        opacity: 0;
    }
    to{
        left: 0;
        opacity: 100%;
    }
}


/////////BACKGROUND STYLES/////////

.HouseCupHero {
     background-repeat: repeat;
     background-size: 300px;
     background-color: $primaryColor;
     overflow: hidden;
     position: relative;
}


/////////SCAFFOLDING/////////

.row {
    z-index: 30;
    position: relative;
    margin-top: 90px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    @media (min-width: 767px) {
        margin-bottom: 170px;
    }
}


/////////HEADER STYLES/////////

.header {
    font-weight: $black;
    color: $baseFontColor;
}


/////////BODY STYLES/////////

.body {
    color: #424242;
    padding-bottom: 15px;
    max-width: 80%;
}

.content {
    position: relative;
    z-index: 999;
}


/////////TEXTURE/////////

.texture {
    width: 250px;
    right: 0;
    
}

.textureContainer {
    width: 100%;
    z-index: 1;
    background-image: url("./images/hero-bg-texture.png");
    background-repeat: repeat;
    pointer-events: none;
    position: absolute;
    bottom: 0;
    mix-blend-mode: luminosity;
    height: 100%;

}


/////////CREST/////////

.crestMedia {
    opacity: 0;
    transition: ease-in-out .3s opacity;
    width: 380px;

    :global(.loaded) .visible & {
        opacity: 1;
    }
}

.crest {
    width: 380px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    visibility: hidden;

    &.visible {visibility: visible;}

    @media (max-width: 768px) {
        margin-right: auto;
        transform: scale(.8);
    }

    @media (max-width: 500px) {
        transform: scale(.6);
    }
}

/////////CREST ELEMENTS SHARED STYLES/////////

.userSm {
    width: 50px;
    position: absolute;
    bottom: 0;
}

.userMed {
    width: 70px;
    position: absolute;
    bottom: 0;
}

.userLg {
    width: 90px;
    position: absolute;
    bottom: 0;
}

.points {
    width: 70px;
    position: relative;
    animation-fill-mode: backwards !important;

    html:not(:global(.loaded)) & {opacity: 0;}

    :global(.loaded) .visible & {
        animation: popout .3s 1s ease-in-out;
    }   
}


/////////RED GROUP/////////

/* animation rules for crest
-- animations start left to right, top to bottom
ORDER
    1. avatars:
        begins: 0s
        ends: 1s
    2. decorations (arrows, zig zags, avatar bling)
        begins: .8s
        ends: 1.5s
    3. numbers and crowns
        begins: 1.3s
        ends: 1.6s
*/

@mixin popout($size, $delay: .0s, $duration: .0s) {
    :global(html:not(.loaded)) & {opacity: 0;}

    :global(.loaded) .visible & {
        animation: popout $duration ease-in-out;
        animation-fill-mode: backwards;

        @if $size == 'small' {
            animation-delay: 0.3s + $delay;
            animation-duration: .3s + $duration;
        }

        @if $size == 'medium' {
            animation-delay: .3s + $delay;
            animation-duration: .3s + $duration;
        }

        @if $size == 'large' {
            animation-delay: .3s + $delay;
            animation-duration: .3s + $duration;
        }
    }
}

@mixin arrowAnimate($delay: .0s) {
    :global(html:not(.loaded)) & {opacity: 0;}

    :global(.loaded) .visible & {
        animation: popout .3s (.4s + $delay) cubic-bezier(0.68, -0.55, 0.27, 1.55);
        animation-fill-mode: backwards;
    }
}

.boyRed {
    @include popout('large');
    top: -10px;
    left: -10px;
}

.girlRed2 {
    top: 80px;
    left: 100px;
    z-index: 100;
    @include popout('small');
}

.girlRed1 {
    bottom: 235px;
    @include popout('medium');
}

.swirl {
    width: 80px;
    position: absolute;
    bottom: 0;
    bottom: 325px;
    @include arrowAnimate();
}

.tenRed {
    bottom: 285px;
    width: 60px;
    animation-delay: .7s;
}


/////////GREEN GROUP/////////

.boyGreen {
    top: 50px;
    right: 4px;
    @include popout('medium', .1s);
}

.girlGreen {
    bottom: 240px;
    right: 70px;
    @include popout('large', .1s);
}

.arrow {
    bottom: 335px;
    right: 60px;
    width: 70px;
    position: absolute;
    @include arrowAnimate(.1s);
}

.twentyFive {
    animation-delay: .9s;
    position: absolute;
    top: 20px;
    right: -30px;
}


/////////BLUE GROUP/////////

.boyBlue {
    position: relative;
    margin-bottom: 50px;
    margin-left: -195px;
    @include popout('medium', .3s);
}

.girlBlue {
    bottom: 94px;
    margin-left: 20px;
    @include popout('large', .3s);
    z-index: 1;
}

.swirlArrow {
    margin-bottom: -15px;
    right: 170px;
    width: 80px;
    position: absolute;
    bottom: 0;
    @include arrowAnimate(.4s);
}

.emphasisLeft {
    margin-bottom: 75px;
    margin-left: -30px;
    transform: rotate(-15deg);
    width: 65px;
    position: absolute;
    bottom: 0;
    @include popout('large', .8s);
    animation-duration: .4s;
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.emphasisRight {
    margin-bottom: 75px;
    margin-left: 90px;
    width: 65px;
    position: absolute;
    bottom: 0;
    @include popout('large', .8s);
    animation-duration: .4s;
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.fifty {
    margin-bottom: 20px;
    margin-left: 15px;
    animation-delay: 1.3s;
}

.crown  {
    position: relative;
    animation-fill-mode: backwards !important;

    :global(html:not(.loaded)) & {opacity: 0;}
    :global(.loaded) .visible & {
        animation: bounceUp .4s 1.1s cubic-bezier(0.68, -0.55, 0, 2.27);
    }
}

.crownLg {
    width: 70px;
    margin-bottom: 170px;
    margin-left: -57px;
}

.crownSm {
    width: 50px;
    margin-bottom: 123px;
    margin-left: -160px;
}


/////////YELLOW GROUP/////////

.boyYellow2 {
    bottom: 130px;
    left: 20px;
    @include popout('medium', .2s);
}

.boyYellow1 {
    bottom: 115px;
    left: 115px;
    @include popout('small', .2s);
}

.girlYellow {
    bottom: 10px;
    left: 80px;
    @include popout('large', .2s);
}

.zigzag {
    bottom: 45px;
    left: -20px;
    width: 50px;
    position: absolute;
    @include arrowAnimate(.2s);
}

.tenYellow {
    margin-bottom: 5px;
    margin-left: -260px;
    width: 60px;
    animation-delay: 1.2s;
}


/////////FOOTER STYLES/////////

@media (min-width: 768px) {
    .footer {
        text-align: left;

    }
}

@media (max-width: 500px) {
    .footer {
        padding-bottom: 110px;

    }
}



/////////BUTTON CONTAINER/////////

.buttonContainer {
    display: flex;
    justify-content: center;
}

@media (min-width: 622px) {
    .buttonContainer {
        justify-content: flex-start;
    }
}


/////////FOREGROUND/////////

.foreground {
    background-repeat: no-repeat;
    position: absolute;
    background-size: cover;
    height: auto;
    width: 900px;
    bottom: 200px;
    z-index: 1;
    right: 0;
}

.mainHill {
    background: url("./images/main-ground.svg");
    position: absolute;
    background-size: cover;
    bottom: -225px;
    height: 400px;
    width: 1350px;
    right: 0px;
}


// /////////MIDDLEGROUND/////////

.backTreeline {
    background: url("./images/back-treeline.svg");
    position: absolute;
    background-size: cover;
    bottom: -20px;
    height: 300px;
    width: 800px;
    left: -70px;
}

.castle {
    background: url("./images/castle.svg");
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: -200px;
    right: -420px;
    height: 700px;
    width: 450px;
 
}

.frontTreeline {
    background: url("./images/front-treeline.svg");
    position: absolute;
    background-size: cover;
    bottom: -40px;
    height: 250px;
    width: 800px;
    left: -30px;
}


// /////////CLOUDS/////////

.cloudLeft {
    background: url("./images/cloud-1.svg");
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    height: 60px;
    width: 350px;
    left: 60px;
    top: 30px;
    animation: float 8s linear infinite alternate;
}
.cloudRight {
    background: url("./images/cloud-2.svg");
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    height: 60px;
    width: 350px;
    right: 0px;
    top: 60px;
    animation: float 8s linear infinite alternate;
}

@media(max-width: 500px) {
    .cloudRight {
        right: -70px;
        top: 100px;
        animation: float 8s linear infinite alternate-reverse;
    }
}

.cloudBottom {
    background: url("./images/cloud-1.svg");
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    height: 60px;
    bottom: 150px;
    width: 350px;
    right: 800px;
    animation: float 8s linear infinite alternate;
}


// /////////TREES/////////

.tree {
    background: url("./images/tree.svg");
    position: absolute;
    background-size: cover;
    z-index: 5;
    bottom: 85px;
    height: 80px;
    width: 40px;
    right: 900px;
}

