@import "../../css/theme.scss";

/////////BACKGROUND COLOR STYLES/////////

.sidebarItemDiscord {
    background: $discord;
}

.sidebarItemSlack {
    background: $slack;
}

.sidebarItemAudentio {
    background: #212121;
}


/////////SIDEBAR ITEM CONTAINER STYLES/////////

.sidebarItemContent {
    padding: 15px;
    display: flex;
}

.sidebarItemTextContainer {
    margin-left: 15px;
}

.sidebarItem {
    border-radius: 8px;
    align-items: center;
    min-width: 0;
    display: block;
    font-size: 1.4rem;
    box-shadow: 0 0 0 1px $borderColor;
    transition: ease-in-out .2s box-shadow, ease-in-out .1s transform;
    height: 100%;
    margin: 0 0 20px 0;
    text-decoration: none;
}

.sidebarItem:hover {
    box-shadow: $boxShadow;
    transform: scale3d(1.04,1.04,1.04);
}

/////////SIDEBAR ITEM BUTTON STYLES/////////

.sidebarButton {
    background: #fff;
    border-radius: 20px;
    color: $discord !important;
    text-align: center;
    padding: 6px 0;
}

.sidebarItemButton {
    font-weight: $black;
    color: #fff;
    font-size: 13px;
}

.sidebarItemButtonContainer {
    padding: 0 15px 15px 15px;
    display: flex;
    justify-content: center;
}

.sidebarItemDiscordButton {
    width: 100%;
    padding: 8px 60px;
    border-radius: 20px;
    text-align: center;
    background: #fff;
    color: $discord;
    font-weight: $black;
}

.sidebarItemSlackButton {
    width: 100%;
    padding: 8px 60px;
    border-radius: 20px;
    text-align: center;
    background: #fff;
    color: $slack;
    font-weight: $black;
}



/////////SIDEBAR ITEM IMAGE STYLES/////////

.sidebarImage {
    width: 80px;
}


/////////SIDEBAR ITEM TEXT STYLES/////////

.sidebarItemParagraph {
    font-size: 13px;
    color: #fff;
    padding-bottom: 5px;
}

.sidebarItemHeading {
    color: #fff;
}

@media (max-width: 500px) {
    .sidebarItemHeading {
        font-size: 24px;
    }
}


/////////DONATE BUTTON STYLES/////////

.donateButton {
    background-color: $darkButton;
    font-weight: 700;
    padding: 15px 30px;
    border-radius: 8px;
    display: flex;
    flex-grow: 1;
    color: #fff;
}

.donateButton:hover {
    box-shadow: $boxShadow;
}

.donateIcon {
    margin-right: 5px;
    font-size: 20px;
}
