.parallax-inner {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    width: 100%;
}

.parallax-outer {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    width: 100%;
}