@import "../../css/theme.scss";

/////////LOGO STYLES/////////

.logo img {
    height: 60px;
    width: 60px;
}

.logo {
    display: grid;
    align-items: center;
    grid-template-columns: 60px auto;
    grid-gap: 10px;
}

.logo:hover {
    text-decoration: none;
}


/////////CONTENT STYLES/////////

.content {
    color: $secondaryColor;
    line-height: 1;
}

.content h5 {
    margin-bottom: 4px;
}
