@import "../../css/theme.scss";

.footer {
    width: 100%;
    z-index: 30;
}


// // /////////SCENERY/////////

.footerHero {
    .scenery {
        background-image: url(./images/main-ground.svg);
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 115px;
        margin-bottom: -50px;
        margin-left: -2px;
        display: none;
    }

    @media (min-width: 768px) {
        .scenery {
           display: block; 
        }
    }
    
    @media (min-width: 1280px) {
        .scenery {
            background-image: url(./images/main-ground.svg);
            background-size: cover;
            background-repeat: no-repeat;
        }
    }
}

.scenery {
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 115px;
    margin-bottom: -50px;
    margin-left: -2px;
    display: flex;
    display: none;
}


// // /////////TREES/////////

.tree {
    background-image: url(./images/tree.svg);
    background-repeat: no-repeat;
    position: absolute;
    left: 120px;
    height: 120px;
    width: 120px;
    margin-top: -50px;
    visibility: hidden;
}

@media (min-width: 1000px) {
    .tree {
        visibility: visible;
    }
}

.trees {
    background-image: url(./images/trees.svg);
    background-repeat: no-repeat;
    position: absolute;
    height: 120px;
    width: 120px;
    margin-top: -40px;
    right: 220px;
    visibility: hidden;
}

@media (min-width: 1050px) {
    .trees {
        visibility: visible;
    }
}


// // /////////FOOTER NAVIGATION/////////

.footerHero {
    .footerNavigation {
        background: linear-gradient(to right, #374364,#67779d);
    }
}

.footerNavigation {
    background: $primaryColor;
}

.menu {
    width: 100%;
}


// // /////////FOOTER NAV LINKS/////////

.navLink {
    font-weight: $black;
    color: $baseFontColor;

    @media (max-width: 600px) {
        font-size: 14px;
    }
}

.footerHero {
    .navLink {
        color: #fff;
    }
}

.footerHero {
    .footerLinks {
        display: flex;
        justify-content: center;
        padding: 20px 0 0 0;
    
        .navLinkContainer {
            display: flex;
            align-items: center;
            text-align: center;
    
            &:after {
                content: '';
                display: inline-block;
                font-weight: $black;
                font-size: 0;
                margin: 0 8px;
                padding: 0;
                border-color: rgba(255,255,255,.2);
                border-left: 2px solid;
                height: 100%;
            }
    
            &:last-child {
                margin-right: 8px;
    
                &:after {
                    display: none;
                }
            }
    
        }
    }
}

.footerLinks {
    display: flex;
    justify-content: center;
    padding: 20px 0 0 0;

    .navLinkContainer {

        &:after {
            content: '|';
            font-weight: $black;
            padding: 0 8px;
            color: $textColorFaint;
        }

        &:last-child {
            margin-right: 8px;

            &:after {
                display: none;
            }
        }

    }
}


// // /////////AUDENTIO CREDITS/////////

.audentio {
    display: flex;
    justify-content: center;
    font-weight: $black;
    padding: 20px 0;
    width: 100%;
    text-decoration: underline;
}

.audentioCredit {
    text-decoration: none;
    color:$textColorFaint;
    font-size: 14px;
}

.footerHero {
    .audentioCredit {
        color: #e3fcfc;
    }
}

.audentioCreditLink {
    text-decoration: underline;
}


// // /////////LEGAL/////////

.footerHero {
    .legal {
        color: #e3fcfc;
        border-top: 1px rgba(255,255,255,.2) solid;

        padding-bottom: 64px;
    }
}

.legal {
    border-top: 1px #dec6ac solid;
    color: $textColorFaint;
    position: relative;
    display: flex;
    align-items: center;
    padding: 20px 0;

    a {
        color: rgba(255,255,255,.8);

    }
}

.copyright {
    font-size: 14px;
}


// // /////////SOCIAL MEDIA LINKS/////////

.socialMediaLinks {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    text-align: right;
    width: 100%;
    justify-content: flex-end;
 
}


// /////////ICON STYLES/////////

.icon {
    display: inline-block;
    font-size: 20px;
    display: inline-flex;
    line-height: 1;
    padding: 5px;

    a, i {display: inline-flex;}
}

.linkedin {
    padding-right: 0;
}

.icon a {
    color:$textColorFaint;
    padding: 10px;
}

.footerHero {
    .icon a {
        color: #e3fcfc;
    }
}


// // /////////TEXTURE CONTAINER/////////

.footerHero {
    .textureContainer {
        background-image: url("./images/hero-bg-texture.png");
        background-repeat: repeat;
        mix-blend-mode: luminosity;
        width: 100%;
        height: 220px;
        position: absolute;
        z-index: 35;
        pointer-events: none;
    }
    
    @media (min-width: 768px) {
        .textureContainer {
            height: 260px;
        }
    }
}